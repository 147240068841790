if (Array.prototype.map === undefined) {
  Array.prototype.map = function(fn) {
    var rv = [];

    var l = this.length;
    for(var i = 0; i < l; l++) {
      rv.push(fn(this[i]));
    }

    return rv;
  };
}