(function($) {
  var props = {};

  var init_props = function(options) {

    var content = '<header class="overlay__header">' + 'Vous utilisez un ancien navigateur.<br /> ' + 'Il est fortement recommandé d\'utiliser une autre navigateur.' + '</header>' + '<div class="row-bottom">' + '<ul class="overlay__list">' + '<li class="overlay__item">' + '<a href="https://www.microsoft.com/fr-be/windows/microsoft-edge" target="_blank" class="overlay__link">' + '<img class="overlay__thumb" src="/sites/all/themes/enabootstrap/images/browsers/edge.png" alt="Microsoft Edge" />' + '<h3 class="overlay__name">Utiliser Edge (Windows 10)</h3>' + '</a>' + '</li>' + '<li class="overlay__item">' + '<a href="https://www.google.com/chrome/browser/desktop/index.html" target="_blank" class="overlay__link">' + '<img class="overlay__thumb" src="/sites/all/themes/enabootstrap/images/browsers/chrome.png" alt="Chrome" />' + '<h3 class="overlay__name">Télécharger Chrome</h3>' + '</a>' + '</li>' + '<li class="overlay__item">' + '<a href="https://www.mozilla.org/fr/firefox/new/" target="_blank" class="overlay__link">' + '<img class="overlay__thumb" src="/sites/all/themes/enabootstrap/images/browsers/firefox.png" alt="Firefox" />' + '<h3 class="overlay__name">Télécharger Firefox</h3>' + '</a>' + '</li>' + '<li class="overlay__item">' + '<a href="http://www.opera.com/fr/download" target="_blank" class="overlay__link">' + '<img class="overlay__thumb" src="/sites/all/themes/enabootstrap/images/browsers/opera.png" alt="Opéra" />' + '<h3 class="overlay__name">Télécharger Opéra</h3>' + '</a>' + '</li>' + '<li class="overlay__item">' + '<a href="https://support.apple.com/downloads/safari" target="_blank" class="overlay__link">' + '<img class="overlay__thumb" src="/sites/all/themes/enabootstrap/images/browsers/safari.png" alt="Safari" />' + '<h3 class="overlay__name">Télécharger Safari</h3>' + '</a>' + '</li>' + '</ul>' + '</div>';

    var default_props = {
      current_browser: window.get_browser_version(),
      title: 'Ancien navigateur détecté',
      content: content,
      modifier: 'old-browser',
    };

    props = $.extend({}, default_props, options);
  };

  var need_display_overlay = function() {
    var current_min = props.minimal_browser_versions.filter(function(min) {
      return min[0] === props.current_browser.browser;
    });

    // Si on est dans
    if (window.location.pathname != '/dpimport/process/newsgatepreview') {
    // if (window.location.pathname != '/605550/article/2019-06-27/ou-regarder-le-quart-de-finale-des-bleues-face-aux-etats-unis-dans-la-region') {
      if (current_min.length !== 0) {
        var min_version = current_min[0][1];

        if (parseInt(props.current_browser.version) < parseInt(min_version)) {
          return true;
        }
      }
    }

    return false;
  };

  var display_overlay = function() {
    window.show_overlay(props.modifier, props.content, props.title);
  };

  window.init_old_browser_message = function(options) {
    init_props(options);

    if (need_display_overlay()) {
      display_overlay();
    }

  };

})(window.rossel.jquery);
