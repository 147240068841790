////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
// CORE
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

// --------------------------------
// --------------------------------
// MODULES
// --------------------------------
// --------------------------------
//=require ../../modules/debug.js
//=require ../../modules/jquery.js
//=require ../../modules/preload-css.js
//=require ../../modules/alerte-cookie.js
//=require ../../modules/header-auto.js
//=require ../../modules/dropdown-auto.js
//=require ../../modules/is-touch-device.js
//=require ../../modules/menu-intelligent.js
//=require ../../modules/menu-sidebar.js
//=require ../../modules/limit-connect.js
//=require ../../modules/selligent.js
//=require ../../modules/old-browsers.js
//=require ../../modules/datalayer.js
