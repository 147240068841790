// Fonction debug
// TODO: Créer un rapport de bug (stockage dans objet history) et transmission en overlay
const log = (tag, tag_name, data, data2 = '', data3 = '') => {
//   config._debug.log.push([tag, tag_name, data, data2, data3]);

  if (debug) {
    let style = '';
    let table = false;

    tag_name = '%c' + tag_name;

    switch (tag) {
      case 'tag':
        style = 'color:magenta';
      break;

      case 'event':
        style = 'color:forestgreen';
      break;

      case 'data':
        style = 'background:darkmagenta;color:white;padding:3px;border-radius:2px';
      break;

      case 'info':
        style = 'background:gray;color:white;padding:3px;border-radius:2px';
      break;

      case 'alert':
        style = 'background:orange;color:white;padding:3px;border-radius:2px';
      break;

      case 'warning':
        style = 'background:red;color:white;padding:3px;border-radius:2px';
      break;

      case 'success':
        style = 'background:green;color:white;padding:3px;border-radius:2px';
      break;

      case 'table':
        style = 'color:tomato;font-size:17px;border-bottom:1px solid tomato;padding:10px';
        table = data;
      break;
    }

    if (table) {
      console.log(tag_name, style);
      console.table(data);
    } else {
      console.log(tag_name, style, data, data2, data3);
    }
  }
}

// DEBUG
let url_string = window.location.href;
let url = new URL(url_string);
const debug = false;

if (url.searchParams && url.searchParams.get('debug')) {
  debug = true;
}
