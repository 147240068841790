//----------------------------------------
// Global: HEADER BEHAVIORS
// 20150303

// update - add initialization function
// 20170307

// update - add specific class for animation in free version
// 20170313
//----------------------------------------

(function($) {
  var header_status = '';
  var scroll_precision = 30;
  var current_scroll_direction = 'top';
  var scrolling_to_top = 0;
  var current_scroll_position = 0;
  var sidebars_or_search_open = false;
  var scroll_top;
  var header_decalage = 36;
  var add_class_for_animation = true;
  var set_top_of_menu = true;

  // dom vars
  var header_offset;
  var header_height;
  var win;
  var body;
  var header;
  var sidebar_left;
  var sidebars;
  var dropdowns;
  //var filter_list;
  var main_content;
  var debug_scroll_classes_on = false;

  var init_dom_vars = function(params) {
    var settings = $.extend(
      {
        //Options
        sidebar_element: $('.sidebar, .pane-ena-direct')
      },
      params
    );

    win = $(window);
    body = $('body');

    header = $('#gr-header');
    sidebar_left = $('#gr-sidebar-left');
    sidebars = settings.sidebar_element; // , #gr-search-menu .dropdown-menu, #gr-social-menu .dropdown-menu, #gr-meteo-menu .dropdown-menu, .gr-topics-navigation .dropdown-menu
    dropdowns = $('#gr-search-menu, #gr-social-menu'); // #gr-meteo-menu, .gr-topics-navigation
    //filter_list = $("#gr-filter-list");
    main_content = $('#gr-main-content');

    header_height = parseFloat($('#gr-header').css('height'));
  };

  var update_scrolls = function() {
    if (window.update_perfect_scrolls_bars) {
      window.update_perfect_scrolls_bars();
    }
  };

  var set_scroll_direction = function(
    previous_position,
    current_scroll_position
  ) {
    current_scroll_direction = 'bottom';

    if (previous_position > current_scroll_position) {
      current_scroll_direction = 'top';
    }

    if (previous_position - current_scroll_position > scroll_precision) {
      scrolling_to_top += 1;
    } else if (current_scroll_direction == 'bottom') {
      scrolling_to_top = 0;
    }
  };

  var debug_set_scroll_classes = function(num, msg) {
    if (debug_scroll_classes_on) {
      if (msg === undefined) {
        msg = '-';
      }
      console.log(
        'header -> set_scroll_classes',
        msg,
        num,
        '- header_status:',
        header_status
      );
    }
  };

  var set_scroll_classes = function() {
    var update_scroll = false;

    if (current_scroll_direction == 'top') {
      body.addClass('scroll-top').removeClass('scroll-bottom');
    } else {
      body.addClass('scroll-bottom').removeClass('scroll-top');
    }

    if (body.hasClass('has-header-perma-small')) {
      return;
    }

    if (
      current_scroll_direction == 'top' &&
      scroll_top <= header_offset.top - header_height
    ) {
      debug_set_scroll_classes(1, '- before');

      if (header_status != 'initial') {
        body
          .removeClass(
            'has-header-fixed has-header-small has-header-hide'
          )
          .addClass('has-header');
        if (typeof force_refresh_section_more == 'function') {
          force_refresh_section_more();
        }
        header_status = 'initial';
        update_scroll = true;
      }

      debug_set_scroll_classes(1, '- after');
    } else if (
      current_scroll_direction == 'top' &&
      scroll_top > header_offset.top - header_height - header_decalage &&
      scroll_top <= header_offset.top - header_decalage
    ) {
      debug_set_scroll_classes(2, '- before');

      if (header_status != 'initial' && header_status != 'fixed') {
        body
          .removeClass(
            'has-header-fixed has-header-small has-header-hide'
          )
          .addClass('has-header');

        if (add_class_for_animation) {
          body.addClass('has-header-animation');
          setTimeout(function() {
            body.removeClass(
              'has-header-animation has-header-small-animation'
            );
          }, 0);
        }

        if (typeof force_refresh_section_more == 'function') {
          force_refresh_section_more();
        }
        header_status = 'initial';
        update_scroll = true;
      }
      debug_set_scroll_classes(2, '- after');
    } else if (
      (current_scroll_direction == 'top' &&
        window.matchMedia('(max-width: 991px)').matches) ||
      (scroll_top >= header_offset.top - header_height &&
        (body.hasClass('has-sidebar') ||
          body.hasClass('has-dropdown-menu')))
    ) {
      debug_set_scroll_classes(3, '- before');

      if (header_status != 'fixed') {
        body
          .removeClass('has-header has-header-small has-header-hide')
          .addClass('has-header-fixed');
        header_status = 'fixed';
        update_scroll = true;
      }
      debug_set_scroll_classes(3, '- after');
    } else if (
      current_scroll_direction == 'bottom' &&
      scroll_top <= header_offset.top - header_decalage
    ) {
      debug_set_scroll_classes(4, '- before');

      if (header_status != 'initial') {
        body
          .removeClass(
            'has-header-fixed has-header-small has-header-hide'
          )
          .addClass('has-header');

        if (typeof force_refresh_section_more == 'function') {
          force_refresh_section_more();
        }
        header_status = 'initial';
        update_scroll = true;
      }

      debug_set_scroll_classes(4, '- after');
    } else {
      if (
        header_status != 'small' &&
        !body.hasClass('has-sidebar') &&
        !body.hasClass('has-dropdown-menu') &&
        window.matchMedia('(min-width: 992px)').matches
      ) {
        debug_set_scroll_classes(5, '- before');

        body
          .removeClass('has-header has-header-fixed')
          .addClass('has-header-small');

        if (typeof force_refresh_section_more == 'function') {
          force_refresh_section_more();
        }

        if (add_class_for_animation && header_status === 'initial') {
          body.addClass('has-header-small-animation');
          setTimeout(function() {
            body.removeClass(
              'has-header-small-animation has-header-animation'
            );
          }, 0);
        }

        $('.gr-navbar-header-center').removeClass('gr-autopromo');
        header_status = 'small';
        update_scroll = true;

        debug_set_scroll_classes(5, '- after');
      } else if (
        header_status != 'hide' &&
        !body.hasClass('has-sidebar') &&
        !body.hasClass('has-dropdown-menu') &&
        window.matchMedia('(max-width: 991px)').matches
      ) {
        debug_set_scroll_classes(6, '- before');

        body
          .removeClass('has-header has-header-fixed has-header-small')
          .addClass('has-header-hide');
        header_status = 'hide';
        update_scroll = true;

        debug_set_scroll_classes(6, '- after');
      }
    }

    if (update_scroll) {
      update_scrolls();
    }
  };

  var hide_direct_in_full_width_page = function() {
    if (sidebar_left.hasClass('is-open')) {
      sidebar_left.removeClass('is-open');

      $('#btn-display-panel-left')
        .html('<span class="fa fa-plug gr-togglers"></span>')
        .attr({
          title: 'Voir le direct',
          'data-original-title': 'Voir le direct'
        });
    }
  };

  var hide_all_opened_contents = function() {
    // hide all sidebars
    sidebars.removeClass('sidebar-open');

    // hide filter list
    //filter_list.hide()

    // hide le direct in full page width
    hide_direct_in_full_width_page();

    // hide le direct in XSSM
    sidebar_left.removeClass('is-visible');
  };

  var onScroll = function() {
    scroll_top = win.scrollTop();
    header_offset = main_content.offset();

    if (scroll_top <= 0) {
      return;
    }

    var previous_position = current_scroll_position;
    current_scroll_position = scroll_top;

    set_scroll_direction(previous_position, current_scroll_position);

    var previous_sidebars_or_search_open = sidebars_or_search_open;

    sidebars_or_search_open =
      !body.hasClass('has-sidebar') &&
      !body.hasClass('has-dropdown-menu');

    set_scroll_classes();
  };

  var hasClass = function(classList) {
    return function(className) {
      return classList.contains(className);
    };
  };

  var onShowSidebars = function() {
    // body has a sidebar displayed

    var hasClassInSidebar = hasClass(this.classList);

    if (hasClassInSidebar('gr-navbar-collapse-services') || hasClassInSidebar('gr-mini-panel-menu')) {
      $('.gr-section-plus-more, #gr-services-button a').addClass('open');
      body.addClass('sidebar-service');
      body.removeClass('sidebar-login sidebar-direct');
    }

    if (hasClassInSidebar('gr-navbar-collapse-login')) {
      $('#gr-services-button .navbar-toggle').removeClass('open');
      body.addClass('sidebar-login');
      body.removeClass('sidebar-service sidebar-direct');
    }

    if (hasClassInSidebar('pane-ena-direct')) {
      $('.gr-direct-toggle').removeClass('open');
      body.addClass('sidebar-direct');
      body.removeClass('sidebar-service sidebar-login');
    }

    body.addClass('has-sidebar');

    onScroll();

    scroll_top = win.scrollTop();
    header_offset = main_content.offset();

    if (scroll_top > header_offset.top) {
      body.removeClass('has-header-small').addClass('has-header-fixed');
      scroll_top = win.scrollTop();
      header_offset = main_content.offset();
      header_status = 'fixed';
    } else {
      if (set_top_of_menu) {
        $('.gr-navbar-collapse-services').css('top', header_offset.top);
      }
      $('.gr-navbar-collapse-login').css(
        'top',
        header_offset.top + scroll_top
      );
    }

    // display the sidebar
    hide_all_opened_contents();
    $(this).addClass('sidebar-open');

    update_scrolls();

    window.viewtiful && window.viewtiful.action('sidebar');
  };

  var onHideSidebars = function() {
    // body do not have a sidebar anymore
    body.removeClass('has-sidebar sidebar-login sidebar-service');
    $('.gr-section-plus-more, #gr-services-button a').removeClass('open');

    // onScroll();

    // add has header small for big screen size (only for mdld)
    if (window.matchMedia('(min-width: 992px)').matches) {
      if (scroll_top > header_offset.top) {
        body.addClass('has-header-small');

        if (typeof force_refresh_section_more == 'function') {
          force_refresh_section_more();
        }
      }
    }

    update_scrolls();

    window.viewtiful && window.viewtiful.remove('sidebar');
  };

  var setSearchFocus = function(bool) {
    var dd_menu = $("[aria-labelledby='gr-search-menu']");
    var search_input_text = dd_menu.find('.form-text');

    setTimeout(function() {
      if (bool) {
        search_input_text.focus();
      } else {
        search_input_text.blur();
      }
    }, 0);
  };

  var onShowDropDowns = function() {
    setSearchFocus(this.id == 'gr-search-menu');

    // body has a search displayed
    if (scroll_top > header_offset.top) {
      body
        .removeClass('has-header-small')
        .addClass('has-dropdown-menu has-header-fixed');
      header_status = 'fixed';
    } else {
      body.addClass('has-dropdown-menu');

      if (body.hasClass('has-header-small')) {
        body
          .removeClass('has-header-small')
          .addClass('has-header-fixed');
        header_status = 'fixed';
      }
    }

    window.viewtiful && window.viewtiful.action('search');
  };

  var onHideDropDowns = function() {
    // body do not have a search anymore
    body.removeClass('has-dropdown-menu');

    if (win.scrollTop() === 0) {
      body.removeClass('has-header-fixed');
      header_status = 'initial';
    }

    window.viewtiful && window.viewtiful.remove('search');
  };

  var create_events = function() {
    win.on('scroll', onScroll);
    win.scrollStop(set_scroll_classes);
    win.on('resize', set_scroll_classes);

    // sidebars (login + account)
    sidebars.on('show.bs.sidebar', onShowSidebars);
    sidebars.on('hide.bs.sidebar', onHideSidebars);

    // dropdowns (search, socials,
    dropdowns.on('show.bs.dropdown', onShowDropDowns);
    dropdowns.on('hide.bs.dropdown', onHideDropDowns);
  };

  var main = function(params) {
    if (params.header_decalage !== undefined) {
      header_decalage = params.header_decalage;
    }

    if (params.add_class_for_animation !== undefined) {
      add_class_for_animation = params.add_class_for_animation;
    }

    if (params.set_top_of_menu !== undefined) {
      set_top_of_menu = params.set_top_of_menu;
    }

    init_dom_vars(params);
    create_events();
    // simulate first scroll
    onScroll();
  };

  window.init_header_params = function(params) {
    main(params);
  };
})(window.rossel.jquery);
