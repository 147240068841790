// MENU ACCORDEON

// vendor
//=require ../vendors/matchMedia.min.js
//=require ../vendors/bootstrap/transition.js

// src
//=require ../src/polyfill-array-map.js
//=require ../src/polyfill-array-filter.js

//=require ../src/sidebar.js
//=require ../src/jquery.vertical-collapse-menu.js
