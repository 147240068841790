(function($) {
  $.fn.limitConnexion = function (popup) {
    var defaults = $.extend({
      title: "<h2>Vous êtes déconnecté</h2>",
      text: "<p>Cet appareil a été déconnecté par le propriétaire du compte.</p><p>Veuillez vous reconnecter pour accéder à l'ensemble des contenus numériques du Journal sur cet appareil.</p>",
      links: "<a href=\"\" class=\"btn btn-primary\"></a><a href=\"javascript:void(0)\" class=\"btn btn-secondary\">Annuler</a>"
    }, popup);

    var html = "";

    // var req = new XMLHttpRequest();
    // req.open('GET', document.location, false);
    // req.send(null);
    // var headers = req.getAllResponseHeaders().toLowerCase();
    // var arr = headers.trim().split(/[\r\n]+/);
    // var headerMap = [];

    // arr.forEach(function (line) {
    //   var parts = line.split(': ');
    //   var header = parts.shift();
    //   var value = parts.join(': ');
    //   headerMap[header] = value;
    // });



    // if (headerMap['x-lc'] === "020") {
    if (getCookie('v_limit')) {
      html = "<div class=\"alert gr-limit-connect popup-container\"><div class=\"backdrop\"></div><div class=\"popup-content\">";
      html += popup.title;
      html += "<div class=\"content\">";
      html += popup.text;
      if (popup.links != "") {
        html += "<div class=\"links\">";
        html += popup.links;
        html += "</div>";
      }
      html += "</div></div></div>";
    }

    return html;
  }
})(rossel.jquery);
