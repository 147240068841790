(function() {
  // https://stackoverflow.com/questions/2400935/browser-detection-in-javascript
  var get_browser_version = function() {

    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    if (isIE11) {
      return {
        browser: 'MSIE',
        version: 11,
      }
    }

    var user_agent = navigator.userAgent;
    var tem;
    var M = user_agent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(user_agent) || [];
      return {
        browser: 'MSIE',
        version: tem[1] || '',
      };
    }

    if (M[1] === 'Chrome') {
      tem = user_agent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) {
        tem = tem
          .slice(1)
          .join(' ')
          .replace('OPR', 'Opera')
          .split(' ');
        return {
          browser: tem[0],
          version: tem[1] || '',
        };
      }
    }

    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

    if ((tem = user_agent.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }

    return {
      browser: M[0],
      version: M[1],
    };
  };

  window.get_browser_version = get_browser_version;
})();
