(function($) {
  $.fn.extend({
    sectionMore: function() {
      var sectionMoreMenu = $(this);
      var sectionPlusMoreLink = '';

      function sectionMoreCalcul($items) {
        var MoreLink = '';
        // $items.children().children('.active').each(function(i, el) {
        //   $(el).parent().prependTo($items);
        // });

        $items.children().map(function(i, el) {
          if (!$(el).overflowVisible(false, false, 'horizontal', $items, 40)) {
            MoreLink = MoreLink + '<li>' + $(el).html() + '</li>';
            $(el).hide();
          }
        });

        return MoreLink;
      }

      $('.gr-section-plus').remove();
      sectionMoreMenu.each(function(){

        $(this).children().show();

        sectionPlusMoreLink = sectionMoreCalcul($(this));

        if (sectionPlusMoreLink != '') {

          $(this).append('<li class="gr-section-plus" id="gr-section-plus"><a href="#"></a><ul class="gr-section-plus-more" id="gr-section-plus-more">' + sectionPlusMoreLink + '</ul></li>');
        }

        $(this).addClass('has-more-menu');
      });



      // Add onclick behavior on tablet screens
      var section_plus = $(".gr-section-plus");
      var menu = section_plus.children(".gr-section-plus-more");
      var btn = $(".gr-section-plus > a");

      if (is_touch_device()) {
        btn.on('click', function(e) {
          e.stopPropagation();
          menu.toggleClass('is-visible');
        });

        $(document).click(function(event) {
     	    if(!$(event.target).closest(btn, menu).length) {
            if(menu.hasClass('is-visible') ) {
              menu.removeClass('is-visible');
            }
          }
        })
      }
    }
  });
})(window.rossel.jquery);
